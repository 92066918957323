.icon-body {
    display: flex;
    flex-direction: column;
    flex-basis: 10%;
    color: wheat;
    width: calc(20% - 1rem);
    position: relative;
    transition: all .2s ease-in-out;
    height: 100%;
    margin-left: .5rem;
    margin-right: .5rem;
    margin-top: 1.5rem;
    max-width: 6rem;
    cursor: pointer;
}

.icon {
    -webkit-filter: invert(100%);
    filter: invert(100%);
    margin-right: .5rem;
    margin-left: .5rem;
    height: 50px;
    width: 50px;
    align-self: center;
}

.icon-body:hover{
    scale: 1.1;
}

.caption {
    font-family: 'Montserrat', sans-serif;
    user-select: none;
    text-align: center;
    vertical-align:middle;
    margin-top: 0.2rem;
    position: absolute;
    bottom: -1.3rem;
    left: -50%;
    right: -50%;
    font-size: 0.9rem;
    color: white;
}