.video-responsive {
    overflow: hidden;
    max-width: 80vh;
    width: 100%;
    display: flex;
}

@supports not (aspect-ratio: 16 / 9) {
    .video-responsive::before {
        float: left;
        padding-top: 56.25%;
        content: "";
    }

    .video-responsive::after {
        display: block;
        content: "";
        clear: both;
    }
}

.video-responsive iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
}
