.content {
  text-align: center;
  background-color: black;
  display: flex;
  flex-direction: column;
}

.policy {
  margin-top: 16px;
  margin-bottom: 16px;
  text-decoration: none;
}


:root {
  --logo-ratio: 1.6;
  --logo-height: 35vw;
  --logo-width: calc(var(--logo-height) * var(--logo-ratio));
  --logo-margin-top: 1rem;
  --logo-margin-bottom: 2rem;
  --instagram-top-margin: 1.5rem;
  --minvolo-image: 4rem;
  --minvolo-margin-bottom: 2rem;
  --minvolo-text-size: 1.5rem;
  --minvolo-caption-size: 1rem;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  :root {
    --logo-height: 30vw;
    --logo-width: calc(var(--logo-height) * var(--logo-ratio));
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) and (max-device-width : 480px) {
  :root {
    --logo-height: 30vw;
    --logo-width: calc(var(--logo-height) * var(--logo-ratio));
    --minvolo-image: 3rem;
    --minvolo-margin-bottom: 1.8rem;
    --minvolo-text-size: 1rem;
    --minvolo-caption-size: 0.8rem;
    --logo-margin-bottom: 1rem;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 320px) {
  :root {
    --logo-height: 30vw;
    --logo-width: calc(var(--logo-height) * var(--logo-ratio));
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  :root {
    --logo-height: 12vw;
    --logo-width: calc(var(--logo-height) * var(--logo-ratio));
    --logo-margin-top: 1rem;
    --logo-margin-bottom: 1rem;
    --instagram-top-margin: 1rem;
    --minvolo-image: 3rem;
    --minvolo-margin-bottom: 1rem;
    --minvolo-text-size: 1.2rem;
    --minvolo-caption-size: 1rem;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {
  :root {
    --logo-height: 12vw;
    --logo-width: calc(var(--logo-height) * var(--logo-ratio));
    --logo-margin-top: 1rem;
    --logo-margin-bottom: 1rem;
    --instagram-top-margin: 1.8rem;
    --minvolo-image: 3rem;
    --minvolo-margin-bottom: 1.8rem;
    --minvolo-text-size: 1.2rem;
    --minvolo-caption-size: 1rem;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 1112px) and (orientation : landscape) {
  :root {
    --logo-height: 12vw;
    --logo-width: calc(var(--logo-height) * var(--logo-ratio));
    --logo-margin-top: 1rem;
    --logo-margin-bottom: 1rem;
    --instagram-top-margin: 1.8rem;
    --minvolo-image: 3rem;
    --minvolo-margin-bottom: 1.8rem;
    --minvolo-text-size: 1.2rem;
    --minvolo-caption-size: 1rem;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  :root {
    --logo-height: 12vw;
    --logo-width: calc(var(--logo-height) * var(--logo-ratio));
    --logo-margin-top: 1rem;
    --logo-margin-bottom: 1rem;
    --instagram-top-margin: 2rem;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
  :root {
    --logo-height: 12vw;
    --logo-width: calc(var(--logo-height) * var(--logo-ratio));
    --logo-margin-top: 1rem;
    --logo-margin-bottom: 2rem;
    --instagram-top-margin: 2rem;
    --minvolo-margin-bottom: 2.5rem;
    --minvolo-text-size: 1.5rem;
    --minvolo-caption-size: 1rem;
  }
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  :root {
    --logo-height: 10vw;
    --logo-width: calc(var(--logo-height) * var(--logo-ratio));
    --logo-margin-top: 2rem;
    --logo-margin-bottom: 2rem;
    --instagram-top-margin: 2rem;
  }
}

.social {
  width: calc(100% - 2rem);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  column-gap: 1rem;
}

.instagram {
  margin-top: var(--instagram-top-margin);
  font-family: 'montserrat-bold';
  font-size: 2rem;
  transition: all .2s ease-in-out;
}

.instagram:hover {
  scale: 1.1
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: underline;
  color: white;
}

.minvolo {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin-bottom: var(--minvolo-margin-bottom);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.minvolo:hover {
  scale: 1.1
}

.minvolo-image {
  border-radius: 50%;
  width: var(--minvolo-image);
  height: var(--minvolo-image);
}

.minvolo-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 1rem;
}

.minvolo-title {
  color: white;
  font-size: var(--minvolo-text-size);
  text-align: start;
  font-family: 'montserrat-semibold';
  text-decoration: underline;
}


.minvolo-caption {
  color: white;
  font-size: var(--minvolo-caption-size);
  font-family: 'montserrat-semibold';
}

.header {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
}

.hide {
  display: none;
  background-image: url(../../assets/logo.png)
}

.logo {
  position: relative;
  width: calc(var(--logo-height) * var(--logo-ratio));
  height: var(--logo-height);
  margin-bottom: var(--logo-margin-bottom);
  margin-top: var(--logo-margin-top);
  pointer-events: none;
  background-size: cover;
  animation: pulse-effect 1s infinite linear alternate-reverse;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/logo.png);
}

.logo::before,
.logo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: calc(var(--logo-height) * var(--logo-ratio));
  height: var(--logo-height);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.logo::before {
  left: -6px;
  opacity: 0.7;
  animation: glitch-effect 0.4s infinite linear alternate-reverse;
  background-image: url(../../assets/logo.png);
  filter: grayscale(50%) sepia(50%) saturate(350%) hue-rotate(240deg) brightness(121%) contrast(121%);
}

.logo::after {
  left: 6px;
  opacity: 0.7;
  animation: glitch-effect 2s infinite linear alternate-reverse;
  background-image: url(../../assets/logo.png);
  filter: grayscale(50%) sepia(50%) saturate(350%) hue-rotate(120deg) brightness(121%) contrast(121%);
}

@keyframes pulse-effect {
  0% {
    scale: 1.0;
  }

  10% {
    scale: 1.008
  }

  40% {
    scale: 1.002
  }

  50% {
    scale: 0.998
  }

  60% {
    scale: 1.004;
  }

  75% {
    scale: 0.998;
  }

  90% {
    scale: 1.002;
  }
}

@keyframes glitch-effect {
  0% {
    clip: rect(calc(200 * 1.6), var(--logo-height), 56px, 0);
  }

  5.8823529412% {
    clip: rect(126px, var(--logo-height), 204px, 0);
  }

  11.7647058824% {
    clip: rect(74px, var(--logo-height), 62px, 0);
  }

  17.6470588235% {
    clip: rect(123px, var(--logo-height), 252px, 0);
  }

  23.5294117647% {
    clip: rect(280px, var(--logo-height), 225px, 0);
  }

  29.4117647059% {
    clip: rect(calc(var(--logo-height) * var(--logo-ratio) * 0.2), var(--logo-height), 202px, 0);
  }

  35.2941176471% {
    clip: rect(calc(var(--logo-height) * var(--logo-ratio) * 0.2), var(--logo-height), 60px, 0);
  }

  41.1764705882% {
    clip: rect(110px, var(--logo-height), 214px, 0);
  }

  47.0588235294% {
    clip: rect(88px, var(--logo-height), 104px, 0);
  }

  52.9411764706% {
    clip: rect(279px, var(--logo-height), 102px, 0);
  }

  58.8235294118% {
    clip: rect(64px, var(--logo-width), 146px, 0);
  }

  64.7058823529% {
    clip: rect(47px, var(--logo-width), 12px, 0);
  }

  70.5882352941% {
    clip: rect(296px, var(--logo-width), 166px, 0);
  }

  76.4705882353% {
    clip: rect(202px, var(--logo-width), 264px, 0);
  }

  82.3529411765% {
    clip: rect(183px, var(--logo-width), 248px, 0);
  }

  88.2352941176% {
    clip: rect(27px, var(--logo-width), 36px, 0);
  }

  94.1176470588% {
    clip: rect(266px, var(--logo-width), 195px, 0);
  }

  100% {
    clip: rect(126px, var(--logo-height), 198px, 0);
  }
}